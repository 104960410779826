<template>
  <div class="activate_wrap">
    <div class="base_title">学习卡登录</div>
    <van-form @submit="onSubmit"
              style="padding:0 6px">
      <van-field v-model="form.account"
                 center
                 label=""
                 clearable
                 @focus="getInput(1)"
                 @blur="getInput(0)"
                 placeholder="请输入学习卡卡号">
      </van-field>
      <!-- 密码 -->
      <div class="van-hairline--bottom"
           :class="{active:activeInput==1}"></div>
      <van-field v-model="form.password"
                 name="密码"
                 label=""
                 clearable
                 placeholder="请输入学习卡密码"
                 :type="passwordType"
                 @focus="getInput(2)"
                 @blur="getInput(0)">
        <!-- :rules="[{ required: true, message: '',trigger :'onChange' }]" -->
        <template slot="right-icon">
          <span class="solts">
            <van-icon name="eye"
                      @click="switchPasswordType"
                      v-if="passwordType==='password'"
                      style="margin-left:4px" />
            <van-icon name="closed-eye"
                      style="margin-left:4px"
                      @click="switchPasswordType"
                      v-else />
          </span>
        </template>
      </van-field>
      <div class="van-hairline--bottom"
           :class="{active:activeInput==2}"></div>
      <!-- 年级 -->
      <van-field readonly
                 clickable
                 label=""
                 :value="level_name"
                 placeholder="选择年级"
                 @focus="getInput(5)"
                 right-icon="arrow-down"
                 @click="showLevel" />
      <div class="van-hairline--bottom"
           :class="{active:activeInput==5}"></div>
      <van-popup v-model="showPicker"
                 round
                 position="bottom"
                 @click-overlay="cancelLevel">
        <div class="popup_container">
          <van-picker show-toolbar
                      ref="levelRef"
                      title="选择年级"
                      :columns="columns"
                      value-key="label"
                      @cancel="cancelLevel"
                      @confirm="onConfirm">
            <template #title>
              <span class="comfirm_title">
                选择年级
              </span>
            </template>
            <template #confirm>
              <span class="comfirm_btn">
                确定
              </span>
            </template>
          </van-picker>
        </div>
      </van-popup>

      <!-- 手机号 -->
      <van-field v-model="form.mobile"
                 label=""
                 clearable
                 placeholder="请输入手机号"
                 @blur="getInput(0)"
                 @focus="getInput(3)" />
      <!-- :rules="[{ required: true, message: '',trigger :'onChange' }]" -->
      <div class="van-hairline--bottom"
           :class="{active:activeInput==3}"></div>
      <!-- 验证码 -->
      <van-field v-model="form.sms"
                 center
                 clearable
                 label=""
                 @focus="getInput(4)"
                 @blur="getInput(0)"
                 placeholder="请输入短信验证码">
        <template #button>
          <van-button size="small"
                      class="btns_sms"
                      style="margin-left:6px"
                      @click="sendSms"
                      native-type="button"
                      v-show="num==0"> 发送验证码</van-button>
          <span v-show="num>0"
                style="color:#ccc;line-height:32px">
            {{num}}s后重新获取
          </span>
        </template>
      </van-field>
      <div class="van-hairline--bottom"
           :class="{active:activeInput==4}"></div>

      <div style="margin:40px 10px 10px;">
        <van-button block
                    :disabled="disabledBtn"
                    type="info"
                    native-type="submit">登 录</van-button>
      </div>
    </van-form>
    <!-- <div class="agree">登录即代表同意<a @click="toPage('/activate/protocol')">用户协议</a>和<a @click="toPage('/activate/policy')">隐私政策</a></div> -->

  </div>
</template>

<script>
import { Toast } from 'vant';
import { getverify, toActivate } from '@/api/activate.js'
import { getverifyM, toActivateM } from '@/api/activateMiddle.js'

export default {
  data () {
    return {
      passwordType: 'password',
      activeInput: 0,
      num: 0,
      form: {
        account: "",
        password: "",
        mobile: "",
        sms: '',
        grade_id: 1,
      },

      intervalId: 0,
      // disabledBtn: true,
      showPicker: false, //显示年级弹窗
      showSubjectPicker: false,//显示科目弹窗
      columns: [{
        id: 1,
        label: '高中'
      },
      {
        id: 2,
        label: '初中'
      }
      ],
      level_name: "高中",//用于显示
      defaultLevelIndex: 0
    }
  },
  computed: {
    disabledBtn () {
      for (let i in this.form) {
        if (!this.form[i]) {
          return true
        }
      }
      return false
    },
  },
  methods: {
    toPage (url) {
      this.$router.push({
        path: url,
        query: {
          level: this.form.grade_id
        }
      })
    },
    switchPasswordType () {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    async onSubmit () {
      if (this.form.grade_id == 1) {
        const { data } = await toActivate({
          mobile: this.form.mobile,
          account: this.form.account,
          password: this.form.password,
          code: this.form.sms
        })
        Toast({
          message: '登录成功',
          position: 'top',
        });
        this.$router.push({
          path: "/activate/info",
          query: {
            "sms_record_id": data.sms_record_id,
            "card_id": data.card_id,
            'level': this.form.grade_id,
            'type':data.type
          }
        })
      }
      if (this.form.grade_id == 2) {
        const { data } = await toActivateM({
          mobile: this.form.mobile,
          account: this.form.account,
          password: this.form.password,
          code: this.form.sms
        })
        Toast({
          message: '登录成功',
          position: 'top',
          className: 'toast_class'
        });
        this.$router.push({
          path: "/activate/info",
          query: {
            "sms_record_id": data.sms_record_id,
            "card_id": data.card_id,
            'level': this.form.grade_id
          }
        })
      }

    },
    showLevel () {
      this.showPicker = true
      this.$nextTick(() => {
        if (this.form.grade_id == 1) {
          this.$refs.levelRef.setIndexes([0])
          this.defaultLevelIndex = 0
        } else {
          this.$refs.levelRef.setIndexes([1])
          this.defaultLevelIndex = 1
        }
      })
    },
    getInput (num) {
      this.activeInput = num
    },
    async sendSms () {
      if (!this.form.mobile) {
        Toast({
          message: '请输入手机号',
          position: 'top',
          className: 'toast_class'
        });
        return
      }
      try {
        if (this.form.grade_id == 1) {
          await getverify({
            mobile: this.form.mobile
          })
          Toast({
            message: '发送成功',
            position: 'top',
            className: 'toast_class'
          });
          this.num = 59
          this.intervalId = setInterval(() => {
            this.num -= 1;
            if (this.num == 0) {
              clearInterval(this.intervalId)
            }
          }, 1000);
        } else if (this.form.grade_id == 2) {
          // 初中
          await getverifyM({
            mobile: this.form.mobile
          })
          Toast({
            message: '发送成功',
            position: 'top',
            className: 'toast_class'
          });
          this.num = 59
          this.intervalId = setInterval(() => {
            this.num -= 1;
            if (this.num == 0) {
              clearInterval(this.intervalId)
            }
          }, 1000);
        }

      } catch (err) {
        // Toast({
        //   message: err.msg,
        //   position: 'top',
        // });
      }
    },

    onConfirm (value, index) {
      this.form.grade_id = value.id;
      this.level_name = value.label
      this.showPicker = false;
      this.defaultLevelIndex = index
    },
    cancelLevel () {
      this.showPicker = false;
      setTimeout(() => {
        this.$refs.levelRef.setIndexes([this.defaultLevelIndex])
      })
    }
  },

  mounted () {
    if (this.$route.query.level == 2) {
      this.form.grade_id = 2
      this.level_name = "初中"
    }
  }

}
</script>

<style lang="scss" scoped>
$titleSize: 28px;
$titleLineHeight: 33px;

$minSize: 12px;
$minLineHeight: 20px;

$normalSize: 14px;

$subTitleSize: 16px;
$subTitleLineHeight: 24px;

.base_title {
  font-weight: 500;
  color: #1a1a1a;
  font-size: $titleSize;
  line-height: $titleLineHeight;
  padding: 40px 20px;
}
.van-cell::after {
  display: none;
}
.van-hairline--bottom {
  width: calc(100% - 24px);
  height: 1px;
  background: #e6e6e6;
  margin: 0 12px;
  display: block;
  margin-bottom: 16px;
}
.active {
  background: #2196f3;
}
.btns_sms {
  background: transparent;
  border: 0;
  color: #2196f3;
}
.popup_container {
  padding: 10px 4px;
  .comfirm_btn {
    color: #2196f3;
    cursor: pointer;
    font-size: $normalSize;
  }
  .comfirm_title {
    font-weight: 700;
    font-size: $subTitleSize;
  }
}
.agree {
  margin-top: 10px;
  text-align: center;
  font-size: $minSize;
  a {
    font-weight: 400;
    color: #2196f3;
  }
}

::v-deep .van-field__control {
  margin-right: 6px;
}
::v-deep .van-popup--bottom {
  // max-width: 600px;
  // left: auto;
}
.activate_wrap {
  max-width: 600px;
  margin: 0 auto;
}

@media screen and (min-width: 710px) {
  .activate_wrap {
    max-width: 546px;
    width: 50%;
  }
  .base_title {
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 30px;
  }
  .van-cell {
    font-size: 16px;
    line-height: 22px;
  }
  .van-button--normal {
    padding: 0 15px;
    font-size: 16px;
  }
  ::v-deep .van-popup--bottom {
    max-width: 600px;
    width: 50%;
    left: auto;
  }
  .btns_sms {
    height: auto;
    line-height: 22px;
    font-size: 14px;
  }
}
@media screen and (min-width: 1000px) {
  .activate_wrap {
    max-width: 614px;
    width: 50%;
  }
  .base_title {
    font-size: 32px;
    line-height: 60px;
    margin-top: 5vh;
    padding-bottom: 40px;
  }
  .van-cell {
    line-height: 30px;
  }
}
</style>
<style lang="scss">
.toast_class {
  top: 10%;
}
</style>