// 激活相关接口
import { instance_demo } from '@/utils/notokenHttp.js'

// 获取验证码
export const getverify = function (params) {
  return instance_demo({
    url: '/api/v1/stucard/verify',
    method: 'get',
    params
  })
}

// 激活
export const toActivate = function (data) {
  return instance_demo({
    url: '/api/v1/stucard/activate',
    method: 'post',
    data
  })
}

// 年级-高中
export const getGrade = function (params) {
  return instance_demo({
    url: '/api/v1/stucard/grade',
    method: 'get',
    params
  })
}

// 地区-高中
export const getDis = function (params) {
  return instance_demo({
    url: '/api/v1/stucard/district',
    method: 'get',
    params
  })
}

// 省份-高中
export const getPro = function (params) {
  return instance_demo({
    url: '/api/v1/stucard/provincial',
    method: 'get',
    params
  })
}

//科目-高中
export const getSubject = function (params) {
  return instance_demo({
    url: '/api/v1/stucard/subject',
    method: 'get',
    params
  })
}



// 基本信息-高中
export const register = function (data) {
  return instance_demo({
    url: '/api/v1/stucard/register',
    method: 'post',
    data
  })
}

//地区
export const getPalceList = function (params) {
  return instance_demo({
    url: '/api/v1/user/register_paper_district',
    method: 'get',
    params
  })
}

//验证验证码
export const verifyUser = function (data) {
  return instance_demo({
    url: '/api/v1/user/verify',
    method: 'post',
    data
  })
}

//开通科目
export const getSelectSubject = function (params) {
  return instance_demo({
    url: '/api/v1/user/select_subject',
    method: 'get',
    params
  })
}

export const invitecodeVerify = function (params) {
  return instance_demo({
    url: '/api/v1/user/check_invite_code',
    method: 'get',
    params
  })
}

export const loginVerify = function (data) {
  return instance_demo({
    url: '/api/v1/user/login_verify',
    method: 'post',
    data
  })
}
export const hasLianTong = function (data) {
  return instance_demo({
    url: '/api/v1/stucard/has_liantong',
    method: 'post',
    data
  })
}

